import React from 'react';
import './contact.css';

const Contact = () => (

    <div className="contact">
      
    </div>
);

export default Contact;